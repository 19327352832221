// eslint-disable-next-line no-redeclare,no-unused-vars
function buildPromptPopup(fields, callback, param) {
  if (!param) param = {};

  var pm = {
    size: 'sm',
    ident: 'info',
    title: info.prompt.prompt,
    closeLabel: info.title.cancel,
    bodyCard: true,
    onTop: true,
  };
  if (param.title) pm.title = param.title;
  if (param.descr) pm.content = param.descr;
  if (param.buttons) pm.buttons = param.buttons;
  else {
    pm.buttons = {};
    var titleSave = 'Übernehmen';
    if (param.titleSave) titleSave = param.titleSave;
    pm.buttons[titleSave] = function () {
      popup.save();
    };
  }
  if (param.button) Object.assign(pm.buttons, param.button);
  var popup = buildPopup(pm);

  var els = [];
  if (isString(fields)) fields = [fields];
  $.each(fields, function (key, value) {
    var pm = {};
    if (isPlainObject(value)) pm = value;
    else pm.label = value;
    if (isPlainObject(fields)) pm.key = key;
    pm.noSave = true;
    pm.view = popup;
    if (!els.length) pm.focus = true;
    if (pm.field == 'select' && !pm.multi && fields.length == 1)
      pm.save = function () {
        popup.save();
      };
    else
      pm.enter = function () {
        popup.save();
      };
    var li = buildFormLine(popup.body, pm);
    els.push(li.element);
  });
  popup.save = function () {
    if (isPlainObject(fields)) {
      var value = {};
      $.each(els, function (i, el) {
        value[el.line.key] = el.val();
      });
    } else {
      value = [];
      $.each(els, function (i, el) {
        value.push(el.val());
      });
    }

    var result;
    if (isPlainObject(fields)) result = callback(value);
    else if (els.length == 1) result = callback(value[0]);
    else result = callback.apply(this, value); // array values to multi param
    if (!param.noClose && result !== false) popup.close();
  };

  return popup;
}
